<template>
  <div>
    <v-breadcrumbs v-if="!isEmbeddedInApp" :items="breadcrumbItems"/>

    <v-card :elevation="isEmbedded ? 0 : 2">
      <v-card-title v-if="!isEmbeddedInApp" class="display-2">Multi-factor authentication</v-card-title>

      <div id="recaptcha-container-id" />

      <div v-if="hasMfaEnabled && !showUnenrollWizard">
        <v-alert type="success" tile>
          <p>You have multi-factor authentication enabled on your account.</p>

          <p>We have <strong>{{ mfaUser.enrolledFactors.map(x => x.phoneNumber).join(', ') }}</strong> as your phone number(s).</p>
        </v-alert>

        <v-card-text>
          <v-btn text color="accent" @click="showUnenrollWizard = true">Disable MFA</v-btn>          
        </v-card-text>
      </div>

      <v-alert v-if="isEmailVerifiedState && !hasMfaEnabled" type="success" tile>
        <h3>Email is verified</h3>
        <p>Your email address has now been verified. Please login again to finalize the MFA setup by verifying your phone number.</p>
      </v-alert>

      <v-alert v-if="!isAvailable" type="warning" tile>
        <p>MFA can only be enabled on accounts which have a username and password. Please create a new account to enable MFA.</p>
      </v-alert>

      <v-alert v-if="state === 'error' || error" type="error" tile>
        <p>{{error}}</p>
      </v-alert>

      <v-stepper v-if="!hasMfaEnabled" v-model="currentStep" flat elevation="0" tile color="">
        <v-stepper-header class="grey lighten-4">
          <v-stepper-step :complete="currentStep > 1" step="1"  >
            Re-authenticate
          </v-stepper-step>

          <v-divider></v-divider>

          <v-stepper-step :complete="currentStep > 2 || (authUser && authUser.emailVerified)" step="2"  >
            Verify email
          </v-stepper-step>

          <v-divider></v-divider>

          <v-stepper-step :complete="currentStep > 3" step="3">
            Verify phone number
          </v-stepper-step>
        </v-stepper-header>

        <v-stepper-items>
          <v-stepper-content step="1">
            <h3>Re-authenticate</h3>
            <v-alert v-if="!hasMfaEnabled" type="info" tile outlined>MFA has not yet been enabled on your account</v-alert>
            <p>
              Enable multi-factor authentication to improve the security of your account. 
            </p>
            <p>
              Please provide your password again to prevent abuse.
            </p>
            <v-form v-if="user" ref="reauthenticateForm" v-model="valid" lazy-validation>
              <v-text-field
                v-model="user.email"
                :label="$t('account.change-password.email')"
                readonly
                disabled
                prepend-icon="fa-user"
                />
              <v-text-field
                v-model="currentPassword"
                :rules="currentPasswordRules"
                :label="$t('account.change-password.current-password')"
                required
                prepend-icon="fa-lock"
                :append-icon="passwordShow ? 'fal fa-eye-slash' : 'fal fa-eye'"
                :type="passwordShow ? 'text' : 'password'"
                @click:append="passwordShow = !passwordShow"
                />

              <v-btn class="mt-8" block color="primary" :disabled="!user.email" :loading="$store.getters.isLoading" @click="reauthenticate" id="verifyNumber">Re-authenticate</v-btn>
            </v-form>            
          </v-stepper-content>

          <v-stepper-content step="2">
            <h3>Verify your Email Address</h3>
            <div v-if="state === 'emailVerificationSend'">
              <v-alert type="success" tile>
                <h4>Verification email on it's way!</h4>

                <p>Please check your mailbox for a verification email and follow the link in the email to verify your email address.</p>

                <p>Please note that it might take up to 5 minutes for this email to arrive.</p>

                <p>Can't find the email after 5 minutes? Make sure to check your spam/junk and 'other' email folders as well.</p>
              </v-alert>
            </div>
            <div v-else-if="authUser && authUser.emailVerified">
              <v-alert type="success" tile>
                <p>You already verified your email address.</p>
                <p><v-btn @click="unverifyEmail">Re-verify</v-btn></p>
              </v-alert>
              <v-btn v-if="authUser.emailVerified" class="" block color="primary" :loading="$store.getters.isLoading" @click="currentStep++" >Next Step</v-btn>
            </div>
            <div v-else-if="profile && authUser.email">

              <v-card-text>   
                <p>
                  Before you can enable multi-factor authentication you need to verify your email address.
                </p>
                <p> 
                  Please verify your email address is correct and confirm. You will receive an email with a link to follow to verify your email. Once your email address is verified you can continue enabling MFA.
                </p>

                <v-text-field v-model="authUser.email" disabled readonly label="Your email" />

                <!-- <v-alert v-if="profile.email != authUser.email" type="info" tile>
                  You might get asked to re-login in order to change your email address.
                </v-alert> -->

                <v-btn class="mt-8" block color="primary" :disabled="!authUser.email" :loading="$store.getters.isLoading" @click="verifyEmail" id="verifyNumber">Verify Email</v-btn>
              </v-card-text>   

            </div>
<!-- <v-btn  class="mt-8" block color="primary" :disabled="!profile.email" :loading="$store.getters.isLoading" @click="verifyEmail" id="verifyNumber">TEST Verify Email</v-btn> -->
          </v-stepper-content>

          <v-stepper-content step="3">
            <h3>Verify phone number</h3>
            <p>
              Please note sending and receiving text messages might be charged by your phone provider.
            </p>
            <p>
              Please note that the phone number you provide will be sent and stored by Google to improve spam and abuse prevention across Google services, including but not limited to multi-factor authentication.
            </p>
            
            <v-text-field v-model="phoneNumber" label="Enter your phone number" hint="Please include your country code (+1, +44, ...)" persistent-hint />

            <v-btn class="mt-8" block :disabled="!phoneNumber" color="primary" :loading="$store.getters.isLoading" @click="submit" id="verifyNumber">Verify</v-btn>

          </v-stepper-content>
        </v-stepper-items>
      </v-stepper>

      <v-stepper v-if="showUnenrollWizard" v-model="currentStep" flat elevation="0" tile color="">
        <v-stepper-header class="grey lighten-4">
          <v-stepper-step :complete="currentStep > 1" step="1"  >
            Re-authenticate
          </v-stepper-step>

          <v-divider></v-divider>

          <v-stepper-step :complete="currentStep > 2" step="2"  >
            Confirm
          </v-stepper-step>

        </v-stepper-header>

        <v-stepper-items>
          <v-stepper-content step="1">
            <h3>Re-authenticate</h3>
            <p>
              Please re-authenticate so we can disable MFA from your account.
            </p>
            <v-form v-if="user" ref="reauthenticateForm" v-model="valid" lazy-validation>
              <v-text-field
                v-model="user.email"
                :label="$t('account.change-password.email')"
                readonly
                disabled
                prepend-icon="fa-user"
                />
              <v-text-field
                v-model="currentPassword"
                :rules="currentPasswordRules"
                :label="$t('account.change-password.current-password')"
                required
                prepend-icon="fa-lock"
                :append-icon="passwordShow ? 'fal fa-eye-slash' : 'fal fa-eye'"
                :type="passwordShow ? 'text' : 'password'"
                @click:append="passwordShow = !passwordShow"
                />

              <v-btn class="mt-8" block color="primary" :disabled="!user.email" :loading="$store.getters.isLoading" @click="reauthenticate" id="verifyNumber">Re-authenticate</v-btn>
            </v-form>            
          </v-stepper-content>

          <v-stepper-content step="2">
            <h3>Confirm</h3>
            <p>
              Disabling MFA from your account will weaken the security of your account.
            </p>
            <v-radio-group v-model="selectedFactor" class="mx-4">
              <v-radio v-for="(item, idx) in mfaUser.enrolledFactors" :key="idx" :label="item.phoneNumber" :value="item.uid"/>
            </v-radio-group>
            <v-btn v-if="hasMfaEnabled" class="mt-8" block color="primary" :disabled="!selectedFactor" :loading="$store.getters.isLoading" @click="unenroll">Disable MFA</v-btn>
          </v-stepper-content>

        </v-stepper-items>
      </v-stepper>


      <v-dialog v-model="displayMfaVerifyDialog" max-width="400px">
        <v-card>
          <v-card-title>Enter Verification Code</v-card-title>
          <v-card-text>
            Please enter the verification code which is sent to the phone number you have registered to use for Multi-Factor Authentication.
          </v-card-text>
          <v-divider></v-divider>
          <v-card-text>
            <v-text-field v-model="verificationCode" label="Verification Code" hint="You should receive a text message shortly." persistent-hint />
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <v-btn color="secondary" text @click="displayMfaVerifyDialog = false">{{$t('shared.cancel')}}</v-btn>
            <v-spacer></v-spacer>
            <v-btn color="secondary" :disabled="!verificationCode" @click="verifyCodeForLogin();" :loading="$store.getters.isLoading">{{$t('shared.confirm')}}</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>              
            

      <v-dialog v-model="displayVerificationCodeDialog" max-width="400px">
        <v-card>
          <v-card-title>Enter Verification Code</v-card-title>
          <v-card-text>
            Please enter the verification code which is sent to the phone number you have entered.
          </v-card-text>
          <v-divider></v-divider>
          <v-card-text>
            <v-text-field v-model="verificationCode" label="Verification Code" hint="You should receive a text message shortly." persistent-hint />
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <v-btn color="secondary" text @click="displayVerificationCodeDialog = false">{{$t('shared.cancel')}}</v-btn>
            <v-spacer></v-spacer>
            <v-btn color="secondary" :disabled="!verificationCode" @click="verifyCode();" :loading="$store.getters.isLoading">{{$t('shared.confirm')}}</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>              
            
    </v-card>
  </div>      
</template>



<script>
import { getAuth } from "firebase/auth";
import { getMultiFactorResolver  } from "firebase/auth";
import { reauthenticateWithCredential, updatePassword, EmailAuthProvider  } from "firebase/auth";
import { updateEmail, updateProfile, sendEmailVerification  } from "firebase/auth";
import { multiFactor, PhoneAuthProvider, PhoneMultiFactorGenerator, RecaptchaVerifier } from "firebase/auth";
import { mapGetters } from "vuex";
import profileService from "@/services/profileService";
import { EventBus } from '@/plugins/eventbus.js';
import { DateTime } from 'luxon'
import tenants from '@/data/tenants.config'
const tenant = tenants.current();

export default {
  name: "ProfileMFA",
  components: {
  },
  props: {
  },
  data() {
    return {
      breadcrumbItems: [
        { text: 'Profile', exact: true, to: {name: 'profile'} },
        { text: 'Multi-factor authentication', disabled: true },
      ],
      currentStep: 1,
      error: null,
      profile: null,
      valid: false,
      currentPassword: '',
      passwordShow: false,
      currentPasswordRules: [
        v => !!v || "Please enter your current password",
      ],
      authUser: null,
      mfaUser: null,
      state: null,
      phoneNumber: null, //'+31618173929',//
      verificationCode: null,
      verificationId: null,
      displayVerificationCodeDialog: false,
      selectedFactor: null,
      displayMfaVerifyDialog: false,
      mfaResolver: null,
      showUnenrollWizard: false,
    };
  },
  async mounted() {
    await this.getProfile();
    
    EventBus.$on('login-state-change', async user => {
      await this.getProfile();
    });
  },
  methods: {
    
    copyProfileId() {
      this.$helpers.copyToClipboard(this.profile.id, this.$toast);
    },

    async unverifyEmail() {
      await updateProfile(this.authUser, {
        emailVerified: false,
      });
    },
    async reauthenticate() {
      try {
        const credential = EmailAuthProvider.credential(
          this.authUser.email,
          this.currentPassword,
        );
        await reauthenticateWithCredential(this.authUser, credential);
        this.currentStep++;
      }
      catch (ex) {
        if (ex.code == 'auth/multi-factor-auth-required') {
          console.log('MFA verify now');
          const auth = getAuth();
          this.mfaResolver = getMultiFactorResolver(auth, ex);
          const recaptchaVerifier = new RecaptchaVerifier('recaptcha-container-id', undefined, auth);
          const phoneInfoOptions = {
              multiFactorHint: this.mfaResolver.hints[0],
              session: this.mfaResolver.session
          };
          const phoneAuthProvider = new PhoneAuthProvider(auth);
          this.verificationId = await phoneAuthProvider.verifyPhoneNumber(phoneInfoOptions, recaptchaVerifier);
          this.displayMfaVerifyDialog = true;
        }
        console.error(ex);
        this.error = "We can't validate your current password. Please ensure you have enter the correct email and current password..";
        return;
      }

    },

    async verifyCodeForLogin() {
      const cred = PhoneAuthProvider.credential(this.verificationId, this.verificationCode);
      const multiFactorAssertion = PhoneMultiFactorGenerator.assertion(cred);
      const response = await this.mfaResolver.resolveSignIn(multiFactorAssertion);
      this.displayMfaVerifyDialog = false;
      this.currentStep++;
    },

    async verifyEmail() {
      const auth = getAuth();
      var user = auth.currentUser;
      const actionCodeSettings = {
        url: `${tenant.homeUrl}account/firebase?tenant=${tenant.id}&redirect=profile-mfa`
      };
      //await updateEmail(user, 'neokoen@gmail.com');
      try {
        /*if (user.email != this.profile.email) {
          await updateEmail(user, this.profile.email);
        }*/
        await sendEmailVerification(user, actionCodeSettings);
        this.state = 'emailVerificationSend';
      }
      catch (error) {
        if (error.code == 'auth/requires-recent-login') {
          console.log('Firebase require re-login');
          this.$router.push({ name: "login", query: { force: true, redirect: 'profile-mfa' }});
          return;
        }
        console.log('firebase error', error.code, error );
      }
    },


    async unenroll() {
      console.log('MFA unenroll now', this.mfaUser, this.mfaUser.enrolledFactors, this.selectedFactor);
      const factor = this.mfaUser.enrolledFactors.find(x => x.uid == this.selectedFactor);
      await this.mfaUser.unenroll(factor);
      await profileService.disableMfa();
      await this.getProfile();
      this.showUnenrollWizard = false;
    },

    async submit() {
      const auth = getAuth();
      var user = auth.currentUser;

      //const recaptchaVerifier = new RecaptchaVerifier('recaptcha-container-id', undefined, auth);
      const recaptchaVerifier = new RecaptchaVerifier("verifyNumber", {
          "size": "invisible",
          "callback": function(response) {
            // reCAPTCHA solved, you can proceed with
            // phoneAuthProvider.verifyPhoneNumber(...).
            //onSolvedRecaptcha();
            console.log('recaptchaVerifier resolved');
            // const phoneInfoOptions = {
            //   phoneNumber: this.phoneNumber,
            //   session: multiFactorSession
            // };

            // const phoneAuthProvider = new PhoneAuthProvider(auth);
            // // Send SMS verification code.
            // this.displayVerificationCodeDialog = true;
            // return phoneAuthProvider.verifyPhoneNumber(phoneInfoOptions, recaptchaVerifier);
          }
      }, auth);
      const multiFactorSession = await multiFactor(user).getSession();
      const phoneInfoOptions = {
        phoneNumber: this.phoneNumber,
        session: multiFactorSession
      };

      const phoneAuthProvider = new PhoneAuthProvider(auth);
      // Send SMS verification code.
      try {
        const result = await phoneAuthProvider.verifyPhoneNumber(phoneInfoOptions, recaptchaVerifier);
        console.log('firebase verification data', result );
        this.verificationId = result;
        this.displayVerificationCodeDialog = true;
      }
      catch(error) {
        if (error.code == 'auth/requires-recent-login') {
          console.log('Firebase require re-login');
          this.$router.push({ name: "login", query: { force: true, redirect: 'profile-mfa' }});
          return;
        }
        if (error.code == 'auth/second-factor-already-in-use') {
          this.error = 'You have already added this phone number to your account.'
          this.state == 'error';
        }
        console.log('firebase error', error.code, error );
      }
      
    },

    async verifyCode() {
      const auth = getAuth();
      var user = auth.currentUser;
//      const recaptchaVerifier = new RecaptchaVerifier('recaptcha-container-id', undefined, auth);
      console.log('firebase verifyCode', this.verificationCode, this.verificationId );

      const multiFactorSession = await multiFactor(user).getSession();
      console.log('Firebase enabling mfa', this.verificationId);
      const cred = PhoneAuthProvider.credential(this.verificationId, this.verificationCode);
      const multiFactorAssertion = PhoneMultiFactorGenerator.assertion(cred);

      // Complete enrollment.
      try {
        const response = await multiFactor(user).enroll(multiFactorAssertion, /*mfaDisplayName:*/null);
        await profileService.enableMfa();
        console.log('Firebase enabling mfa result', response);
        this.displayVerificationCodeDialog = false;
      }
      catch(error) {
        if (error.code == 'auth/requires-recent-login') {
          console.log('Firebase require re-login');
          this.$router.push({ name: "login", query: { force: true, redirect: 'profile-mfa' }});
          return;
        }
        console.log('firebase error', error.code, error );
      };
    },

    async getProfile() {
      if (this.user) {
        var response = await profileService.get();
        this.profile = response.data;

        const auth = getAuth();
        this.authUser = auth.currentUser;
        this.mfaUser = multiFactor(this.authUser);
        console.log('this.authUser', this.authUser, this.mfaUser);
      }
    },
  },
  computed: {
    isValid() {
      return this.confirmed;
    },
    isEmailVerifiedState() {
      return this.$route.query.state == 'EMAIL_VERIFIED';
    },
    isEmbedded() {
      return this.$route.query.view === 'embed' || this.$store.state.view == 'embed' || this.isEmbeddedInApp;
    },
    isEmbeddedInApp() {
      return this.$route.query.view === 'app' || this.$store.state.view == 'app';
    },
    isAvailable() {
      return this.authUser && this.authUser.providerId == "firebase" && !this.authUser.isAnonymous;
    },
    hasMfaEnabled() {
      return this.mfaUser && this.mfaUser.enrolledFactors && this.mfaUser.enrolledFactors.length;
    },
    ...mapGetters({
      user: "user"
    })
  },
  watch: {
  },
};
</script>
<style lang="scss">
</style>

